<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('jilu.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-tabs
        class="order-List"
        color="#004ea3"
        title-active-color="#004ea3"
        @change="change"
      >
        <van-tab :title="$t('jiaoyijilu')" name="tab1">
          <van-tabs
            class="order-List"
            color="#004ea3"
            title-active-color="#004ea3"
            @change="change"
          >
            <van-tab :title="$t('chongzhi')" name="tab1-1">
              <van-list
                v-if="recharges.length"
                v-model="loading"
                :finished="finished"
                :finished-text="$t('common.nomore')"
              >
                <van-row
                  class="currency-Increase"
                  v-for="item in recharges"
                  :key="item.id"
                >
                  <van-col
                    :span="12"
                    style="text-align:left"
                  >
                    <span>{{ item.code }}</span>
                    <br />
                    <span style="color:reg(128,128,128)">
                      {{ $t('chong-bi-shu-liang') }} : <span>{{ item.recharge_num }}</span>
                    </span>
                    <br />
<!--                    <span style="color:reg(128,128,128)">
                      {{ $t('bei-zhu-itemproductname') }} : {{ item.remark }}
                    </span> //备注 -->
                  </van-col>
                  <van-col
                    :span="12"
                    class="currency-time"
                  >
                    <span>
                      <span v-if="item.status === '0'">
                        {{ $t('dai-shen-he') }} </span>
                      <span v-if="item.status === '1'">
                        {{ $t('tong-guo') }} </span>
                       <span v-if="item.status === '2'">
                        {{ $t('bo-hui') }} </span>
                      <br/>
                      <span style="color: rgb(128, 128, 128);">
                        {{ $t("chong-bi-lei-xing")  }}
                        ：{{ item.product_type }}
                        >
                      </span>
                      <br />
                      <span style="color: rgb(128, 128, 128);">
                        {{ item.createtime | dateformat }}
                      </span>
                    </span>
                  </van-col>
                </van-row>
              </van-list>
              <van-empty
                :description="$t('common.nomore')"
                v-else
              />
            </van-tab>
            <van-tab :title="$t('tibi')" name="tab1-2">
              <van-list
                v-if="withdraws.length"
                v-model="loading"
                :finished="finished"
                :finished-text="$t('common.nomore')"
              >
                <van-row
                  class="currency-Increase"
                  v-for="item in withdraws"
                  :key="item.id"
                >
                  <van-col
                    :span="12"
                    style="text-align:left"
                  >
                    <span>{{ item.code }}</span>
                    <br />
                    <span style="color:reg(128,128,128)">
                      {{ $t('tix.number') }} : <span>{{ item.withdraw_num }}</span>
                    </span>
                    <br/>
<!--                    <span style="color:reg(128,128,128)">//备注
                      {{ $t('bei-zhu-itemproductname') }} : {{ item.remark }}
                    </span> -->
                  </van-col>
                  <van-col
                    :span="12"
                    class="currency-time"
                  >
                    <span>
                      <span v-if="item.status === '0'">
                        {{ $t('dai-shen-he') }} </span>
                      <span v-if="item.status === '1'">
                        {{ $t('tong-guo') }} </span>
                       <span v-if="item.status === '2'">
                        {{ $t('bo-hui') }} </span>
                      <br />
                       <span style="color: rgb(128, 128, 128);">
                        {{ $t('ti-bi-lei-xing') }}
                        ：{{ item.product_type }}
                        >
                      </span>
                      <br />
                      <span style="color: rgb(128, 128, 128);">
                        {{ item.createtime | dateformat }}
                      </span>
                    </span>
                  </van-col>
                </van-row>
              </van-list>
              <van-empty
                :description="$t('common.nomore')"
                v-else
              />
            </van-tab>
            <van-tab :title="$t('hzjilu')" name="tab1-3">
               <van-list
                v-if="transfers.length"
                v-model="loading"
                :finished="finished"
                :finished-text="$t('common.nomore')"
              >
                <van-row
                  class="currency-Increase"
                  v-for="item in transfers"
                  :key="item.id"
                >
                  <van-col
                    :span="12"
                    style="text-align:left"
                  >
                    <span>{{ item.code }}</span>
                    <br />
                     <span style="color:reg(128,128,128)">
                      {{ $t('huaz.number') }} : <span>{{ item.num }}</span>
                    </span>
                    <br />
                   <span style="color:reg(128,128,128)">
                       {{ $t('hua-zhuan-lei-xing') }}:
                       <span v-if="item.form === 'usable'">
                         {{ $t('hzbibi') }} </span>
                       <span v-if="item.form === 'legal_tender'">
                         {{ $t('hzfabi') }} </span>
                         <span v-if="item.form === 'Defi'">
                           {{ $t('hzdefi') }} </span>
                           <span v-if="item.form === 'contract'">
                             {{ $t('hzcx') }} </span>
                             >>>
                       <span v-if="item.to === 'usable'">
                         {{ $t('hzbibi') }} </span>
                       <span v-if="item.to === 'legal_tender'">
                         {{ $t('hzfabi') }} </span>
                         <span v-if="item.to === 'Defi'">
                           {{ $t('hzdefi') }} </span>
                           <span v-if="item.to === 'contract'">
                             {{ $t('hzcx') }} </span>
                       <br/>
                    </span>
                  </van-col>
                  <van-col
                    :span="12"
                    class="currency-time"
                  >
                    <span>
                      <span v-if="item.status === '0'">
                        {{ $t('shi-bai') }} </span>
                      <span v-if="item.status === '1'">
                        {{ $t('cheng-gong') }} </span>
                      <br/>
                      <span style="color: rgb(128, 128, 128);">
                      </span>
                      <br />
                      <span style="color: rgb(128, 128, 128);">
                        {{ item.createtime | dateformat }}
                      </span>
                    </span>
                  </van-col>
                </van-row>
              </van-list>
              <van-empty
                :description="$t('common.nomore')"
                v-else
              />
            </van-tab>
          </van-tabs>
        </van-tab>
        <van-tab :title="$t('cxjilu')" name="tab2">
          <van-list
            v-if="orders.length"
            v-model="loading"
            :finished="finished"
            :finished-text="$t('common.nomore')"
          >
            <van-row
              class="currency-Increase"
              v-for="item in orders"
              :key="item.id"
            >
              <van-col
                :span="12"
                style="text-align:left"
              >
                <span>{{ item.code }}</span>
                <br />
                <span style="color:reg(128,128,128)">
                  {{ $t('jilu.buy') }}:
                  <span>{{ item.total_price }}</span>
                </span>
                <br />
                <span style="color:reg(128,128,128)">
                  {{ $t('jilu.biz') }}: {{ item.product_name }}
                </span>
              </van-col>
              <van-col
                :span="12"
                class="currency-time"
              >
                <span>
                  <span v-if="item.status === '0'">
                    {{ $t('jiao-yi-zhong') }} </span>
                  <span v-if="item.status === '1'">
                    {{ $t('yi-wan-cheng') }} </span>
                  <br />
                  <span style="color: rgb(128, 128, 128);">{{ $t('jilu.price') }}：{{ item.profit }}
                    >
                  </span>
                  <br />
                  <span style="color: rgb(128, 128, 128);">
                    {{ item.createtime | dateformat }}
                  </span>
                </span>
              </van-col>
            </van-row>
          </van-list>
          <van-empty
            :description="$t('common.nomore')"
            v-else
          />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
      recharges: [],
      withdraws: [],
      transfers: [],
      finished: true,
      loading: false
    }
  },
  created() {
    this.getRecharges()
    this.getTransfers()
  },
  mounted() {},
  methods: {
    // 创新记录
    async getOrders() {
      const { data } = await this.$http.get('/home/trade/get_orders')
      if (data) {
        if (data.code === 200) {
          this.orders = data.data
        }
      }
    },
    // 充值记录
    async getRecharges() {
      const { data } = await this.$http.get('/home/trade/get_recharges')
      if (data) {
        if (data.code === 200) {
          this.recharges = data.data
        }
      }
    },
    // 提币记录
    async getWithdraws() {
      const { data } = await this.$http.get('/home/trade/get_withdraws')
      if (data) {
        if (data.code === 200) {
          this.withdraws = data.data
        }
      }
    },
    // 提币记录
    async getTransfers() {
      const { data } = await this.$http.get('/home/trade/get_transfers')
      if (data) {
        if (data.code === 200) {
          this.transfers = data.data
        }
      }
    },
    change(name) {
      console.log(name)
      switch (name) {
        case 'tab2':
          this.getOrders()
          break
        case 'tab1-1':
          this.getRecharges()
          break
        case 'tab1-2':
          this.getWithdraws()
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding: 64px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
  /deep/.van-tab {
    font-size: 1.04rem;
  }
  .currency-Increase {
    background-color: #fff;
    color: #000;
    padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
    line-height: 1.6rem;
    font-size: 0.8rem;
    display: flex;
    border-bottom: 0.02667rem solid #e5e5e5;
    .currency-time {
      text-align: right;
    }
  }
}
</style>
